import React from 'react'
import { graphql } from 'gatsby'

import Layout from 'src/layouts'
import PDP from 'src/components/PDP'
import SEO from 'src/components/shared/SEO'

import { IPdpTemplateProps } from 'src/types/pdp-template'

const PdpTemplate: React.FC<IPdpTemplateProps> = ({ data, location }) => {
  const { title, shortDescription, seo } = data?.productsJson
  const { edges } = data?.allProductsJson

  let bundles

  if (edges) {
    bundles = edges.map((b: any) => b.node)
  }

  return (
    <>
      <SEO
        title={seo?.title || title}
        description={seo?.description || shortDescription}
        keywords={seo?.keywords || []}
        location={location}
      />

      <Layout location={location}>
        <PDP bundles={bundles} data={data?.productsJson} />
      </Layout>
    </>
  )
}

export default PdpTemplate

export const query = graphql`
  query ($slug: String!, $bundleProducts: [String]) {
    allProductsJson(filter: { slug: { in: $bundleProducts } }) {
      edges {
        node {
          title
          oneLiner
          category
          buttonColors {
            bg
            color
          }
          imgItems {
            img {
              childImageSharp {
                gatsbyImageData(width: 500)
              }
            }
            text
          }
          variantSlugs
          cards {
            mainImg {
              childImageSharp {
                gatsbyImageData(width: 500)
              }
            }
            howToUse
            repairs {
              title
              items
            }
          }
          ingredients {
            title
            items {
              name
              image {
                childImageSharp {
                  gatsbyImageData(width: 320)
                }
              }
              alt
              description
            }
            nonIngredients {
              title
              items
            }
          }
          slug
          perChew
          colors {
            bg
            color
            highlight
          }
          guarantee {
            image {
              childImageSharp {
                gatsbyImageData(width: 464)
              }
            }
            paragraphTextColor
            buttonColor
            textColor
            cta
          }
          approvedBy {
            title
            colors {
              bg
              color
            }
            owners {
              headline
              description
              name
              breed
              age
              weight
              dosage
              src {
                childImageSharp {
                  gatsbyImageData(width: 600)
                }
              }
            }
          }
          quality
          expectations {
            title
            src {
              childImageSharp {
                gatsbyImageData(width: 600)
              }
            }
            dosage
            body
            weeks {
              title
              phases {
                phase
                bold
              }
            }
          }
          buyOptions {
            title
            image {
              childImageSharp {
                gatsbyImageData(width: 300)
              }
            }
            packType
            isSubscription
            ctaText
            specialText
            details
          }
          card1 {
            color
            alt
            copy {
              ingredients {
                amount
                name
              }
              body
              header
            }
            src {
              childImageSharp {
                gatsbyImageData(width: 600)
              }
            }
            fullSizeSrc {
              childImageSharp {
                gatsbyImageData(width: 400)
              }
            }
          }
          card2 {
            color
            alt
            title
            copy {
              keyIngredients {
                name
                description
              }
              ingredients {
                amount
                name
              }
              body
              header
            }
            src {
              childImageSharp {
                gatsbyImageData(width: 500)
              }
            }
            fullSizeSrc {
              childImageSharp {
                gatsbyImageData(width: 500)
              }
            }
          }
          card3 {
            color
            alt
            title
            copy {
              ingredients {
                amount
                name
                icon
              }
              body
              header
              testimonials {
                stars
                headline
                quote
                name
                location
              }
            }
            src {
              childImageSharp {
                gatsbyImageData(width: 500)
              }
            }
          }
          card4 {
            alt
            title
            copy {
              body
              header
            }
            src {
              childImageSharp {
                gatsbyImageData(width: 500)
              }
            }
            fullSizeSrc {
              childImageSharp {
                gatsbyImageData(width: 500)
              }
            }
          }
        }
      }
    }
    productsJson(slug: { eq: $slug }) {
      slug
      outOfStock
      category
      title
      isBundle
      perChew
      colors {
        bg
        highlight
        color
      }
      buttonColors {
        bg
        color
      }
      variantSlugs
      seo {
        title
        description
        keywords
      }
      faqs {
        answer
        question
      }
      vetQuote {
        author
        quote
        imageKey
      }
      shortDescription
      oneLiner
      imgItems {
        img {
          childImageSharp {
            gatsbyImageData(width: 500)
          }
        }
        text
      }
      cards {
        mainImg {
          childImageSharp {
            gatsbyImageData(width: 500)
          }
        }
        howToUse
        repairs {
          title
          items
        }
      }
      ingredients {
        title
        items {
          name
          image {
            childImageSharp {
              gatsbyImageData(width: 405)
            }
          }
          alt
          description
        }
        nonIngredients {
          title
          items
        }
      }
      junip {
        productIDs
        remoteProductIDs
      }
      approvedBy {
        title
        colors {
          bg
          color
        }
        owners {
          headline
          description
          name
          breed
          age
          weight
          dosage
          src {
            childImageSharp {
              gatsbyImageData(width: 600)
            }
          }
        }
      }
      quality
      expectations {
        title
        src {
          childImageSharp {
            gatsbyImageData(width: 600)
          }
        }
        body
        dosage
        weeks {
          title
          phases {
            phase
            bold
          }
        }
      }
      buyOptions {
        title
        image {
          childImageSharp {
            gatsbyImageData(width: 300)
          }
        }
        packType
        isSubscription
        ctaText
        specialText
        details
      }
      card1 {
        color
        alt
        copy {
          ingredients {
            amount
            name
          }
          body
          header
        }
        src {
          childImageSharp {
            gatsbyImageData(width: 600)
          }
        }
        fullSizeSrc {
          childImageSharp {
            gatsbyImageData(width: 400)
          }
        }
      }
      card2 {
        color
        alt
        title
        copy {
          keyIngredients {
            name
            description
          }
          ingredients {
            amount
            name
          }
          body
          header
        }
        src {
          childImageSharp {
            gatsbyImageData(width: 500)
          }
        }
        fullSizeSrc {
          childImageSharp {
            gatsbyImageData(width: 500)
          }
        }
      }
      card3 {
        color
        alt
        title
        copy {
          ingredients {
            amount
            name
            icon
          }
          body
          header
          testimonials {
            stars
            headline
            quote
            name
            location
          }
        }
        src {
          childImageSharp {
            gatsbyImageData(width: 500)
          }
        }
      }
      card4 {
        alt
        title
        copy {
          body
          header
        }
        src {
          childImageSharp {
            gatsbyImageData(width: 500)
          }
        }
        fullSizeSrc {
          childImageSharp {
            gatsbyImageData(width: 500)
          }
        }
      }
      carouselImages {
        alt
        src {
          childImageSharp {
            gatsbyImageData(width: 1400)
          }
        }
      }
      guarantee {
        image {
          childImageSharp {
            gatsbyImageData(width: 464)
          }
        }
        paragraphTextColor
        buttonColor
        textColor
        cta
      }
    }
  }
`
