import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Headline, LargeP, P, H1, H6, MonoP } from '../shared/text'
import { colors, fonts } from '../../styles/variables'
import { media } from '../../styles/util'
import CtaButtons from './AboveTheFold/CtaButtons'
import HowToImg from '../../data/products/images/paw-hero/how-to.svg'
import HowToImgSkin from '../../data/products/images/skin-hero/how-to.svg'
import scrollToTop from '../../utils/scrollToTop'
import { PrimaryButton } from 'src/components/shared/buttons'
import Certs from '../../data/products/images/poop-bags/certs.png'

const Section = styled.section<{ bg: string }>`
  width: 100%;
  background-color: ${(props) => colors[props.bg]};
`

const Container = styled.div<{ noPadding?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  ${media.mobile`
    padding: ${(props) => (props.noPadding ? '20px' : '50px 20px 60px 20px')};
  `}
  ${media.tablet`
    padding: 86px 20px 60px 20px;
  `}
  ${media.desktop`
    padding: ${(props) => (props.noPadding ? '40px 20px 20px 20px' : '86px 20px 60px 20px')};
    max-width: 1400px;
  `}
  color: ${colors.navy};

  button {
    width: 100%;
    max-width: 474px;
  }
`

const Header = styled(Headline)<{ color: string }>`
  text-align: center;
  margin: 0 auto;
  white-space: pre-line;
  text-transform: capitalize;
  color: ${(props) => (props.color ? colors[props.color] : colors.navy)};
  ${media.mobile`
    font-size: 64px;
  `}
  ${media.tablet`
    max-width: 600px;
  `}
  ${media.desktop`
    font-size: 100px;
    max-width: 1334px;
  `}
`

const SectionHeader = styled('h3')`
  font-family: ${fonts.athletics.medium};
  background-color: ${colors.lightBlue};
  color: ${colors.navy};
  text-align: center;
  font-size: 36px;
  padding: 10px 0;
  margin: 0;
`

const ListItems = styled('ul')<{ dot: string }>`
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 18px 25px;
  li {
    font-family: ${fonts.larsseit.normal};

    ${media.mobile`
      font-size: 24px;
    `}
    ${media.tablet`
      font-size: 24px;
    `}
    ${media.desktop`
      font-size: 30px;
    `}


    &::before {
      content: '• ';
      color: ${(props) => colors[props.dot]};
      font-weight: bold;
      display: inline-block;
      width: 1em;
    }
  }
`

const HowTo = styled('div')<{ pb?: string }>`
  padding: 34px 34px ${(props) => (props.pb ? '30px' : '0')} 34px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  p {
    font-family: ${fonts.larsseit.normal};
    color: ${colors.navy};
    font-size: 24px;
    white-space: pre-line;
    margin-bottom: ${(props) => (props.pb ? '-20px' : 'auto')};
  }

  img {
    justify-self: flex-end;
    display: block;
  }
`

const Grid = styled('div')<{ noPadding?: boolean }>`
  width: 100%;
  display: grid;
  place-items: start;
  align-content: stretch;
  padding-bottom: 50px;

  ${media.mobile`
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-gap: 20px;
    margin-top: ${(props) => (props.noPadding ? '0' : '50px')};
    padding-bottom: ${(props) => (props.noPadding ? '20px' : '20px')};
  `}
  ${media.tablet`
    margin-top: 50px;
    grid-template-columns: repeat(3, 1fr);
  `}
  ${media.desktop`
    max-width: 1300px;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 28px;
    margin-top: ${(props) => (props.noPadding ? '0' : '60px')};
    padding-bottom: ${(props) => (props.noPadding ? '20px' : '50px')};
    grid-template-rows: auto;
  `}
`

const Card = styled('div')<{ bg?: string }>`
  overflow: hidden;
  position: relative;

  background-color: ${(props) => props.bg};
  color: ${colors.navy};
  border-radius: 20px;

  margin: 0 auto;

  width: 100%;
  height: fit-content;

  ${media.mobile`
    height: fit-content;
  `}
  ${media.tablet`
    height: fit-content;
  `}
  ${media.desktop`
    height: 530px;
  `}


  .ant-collapse-borderless {
    background-color: transparent;
  }
`

const CardHeader = styled('div')`
  background-color: #fff;
  position: absolute;
  top: 0;
  z-index: 1;
  padding: 20px;
  h3 {
    font-family: ${fonts.athletics.medium};
    color: ${colors.navy};
    text-align: center;
    font-size: 32px;
    line-height: 1;
    margin: 0;
  }
`

const Cards = ({ data }) => {
  const { slug, oneLiner, colors, cards, imgItems } = data
  const jumpTop = () => scrollToTop()

  let noPadding

  switch (slug) {
    case 'clip-on-canvas-poop-bag-holder':
      noPadding = true
      break
    case 'plaque-patrol-dental-chews-small':
    case 'plaque-patrol-dental-chews-large':
      noPadding = true
      break
    default:
      noPadding = false
  }

  return (
    <Section
      bg={slug === 'poop-bags' || slug === 'clip-on-canvas-poop-bag-holder' ? 'navy' : colors?.bg}
    >
      <Container noPadding={noPadding}>
        {slug !== 'clip-on-canvas-poop-bag-holder' && (
          <Header color={slug === 'poop-bags' ? 'white' : colors?.color}>{oneLiner}</Header>
        )}
        {slug === 'poop-bags' && (
          <img style={{ maxWidth: 570, width: '100%', paddingTop: 30 }} src={Certs} alt="no icon" />
        )}
        <Grid noPadding={slug === 'clip-on-canvas-poop-bag-holder'}>
          {slug === 'poop-bags' ||
          slug === 'clip-on-canvas-poop-bag-holder' ||
          slug === 'plaque-patrol-dental-chews-large' ||
          slug === 'plaque-patrol-dental-chews-small' ? (
            <>
              {imgItems.map((card, index) => (
                <Card key={index}>
                  {slug !== 'clip-on-canvas-poop-bag-holder' &&
                    slug !== 'plaque-patrol-dental-chews-small' &&
                    slug !== 'plaque-patrol-dental-chews-large' && (
                      <CardHeader>
                        <h3>{card.text}</h3>
                      </CardHeader>
                    )}
                  <GatsbyImage
                    image={getImage(card.img)}
                    style={{
                      width: '100%',
                      height: '100%'
                    }}
                    imgStyle={{ objectFit: 'cover' }}
                  />
                </Card>
              ))}
            </>
          ) : (
            <>
              <Card>
                <GatsbyImage
                  image={getImage(cards.mainImg)}
                  style={{
                    width: '110%',
                    height: '100%'
                  }}
                  imgStyle={{ objectFit: 'cover' }}
                />
              </Card>

              <Card bg="white">
                <HowTo pb={slug === 'skin-hero'}>
                  <p>{cards?.howToUse}</p>
                  <img src={slug === 'skin-hero' ? HowToImgSkin : HowToImg} alt="How to use" />
                </HowTo>
              </Card>

              <Card bg="white">
                <SectionHeader>{cards?.repairs?.title}</SectionHeader>
                <ListItems dot={colors?.bg}>
                  {cards?.repairs?.items?.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ListItems>
              </Card>
            </>
          )}
        </Grid>

        {slug !== 'poop-bags' &&
          slug !== 'clip-on-canvas-poop-bag-holder' &&
          slug !== 'plaque-patrol-dental-chews-small' &&
          slug !== 'plaque-patrol-dental-chews-large' && (
            <PrimaryButton onClick={jumpTop} arrow copy={'Buy Now'} />
          )}
      </Container>
    </Section>
  )
}

export default Cards
