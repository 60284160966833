import React, { useState } from 'react'
import styled from 'styled-components'
import { Select } from 'antd'
import { DownOutlined } from '@ant-design/icons'

import { P, SmallP } from 'src/components/shared/text'
import { colors } from 'src/styles/variables'
import { media } from 'src/styles/util'
import DogIcon from 'src/assets/img/dog-icons/doberman-left-navy.svg'

const Container = styled('div')<{ open: boolean }>`
  position: relative;
  cursor: pointer;

  width: 100%;

  background-color: ${colors.lightGrey};
  border: 1px solid ${colors.navy};

  border-radius: 4px;

  text-align: left;

  ${media.mobile`
    margin: 0 0 12px 0;
    max-width: 240px;
  `}
  ${media.desktop`
    margin: 0 0 10px 0;
    max-width: 304px;
  `}

  & svg {
    width: 14px;
    transition: 150ms ease-in-out transform;
    transform: ${(props) => (props.open ? 'rotate(180deg)' : '0')};
    align-self: end;
    margin-right: 3px;
  }

  & .ant-select {
    min-height: 57px;

    ${media.mobile`
      width: 240px;
    `}
    ${media.desktop`
      width: 304px;
    `}
  }

  & .rc-virtual-list {
    ${media.mobile`
      width: 240px;
    `}
    ${media.desktop`
      width: 304px;
    `}
  }

  & .ant-select-dropdown {
    top: 42px !important;
    left: -1px !important;
    padding-bottom: 20px;
  }

  & .ant-select-single {
    border: none !important;
    margin: -2px auto 0 auto !important;
  }

  & .ant-select-selector {
    min-height: 60px;
    border: none;
    padding: 0 0 10px 0 !important;
    margin: 0 auto !important;
    color: ${colors.navy} !important;

    width: 100%;

    ${media.mobile`
      max-width: 250px;
    `}
    ${media.desktop`
      max-width: 304px;
    `}
  }

  & span.ant-select-selection-search {
    display: none !important;
  }
`

const Row = styled.div`
  display: grid;
  align-items: center;

  border-top: 1px solid ${colors.navy};

  padding: 10px 0;

  ${media.mobile`
    grid-template-columns: 64px 1fr;
    grid-column-gap: 10px;
    margin: 0 18px;
  `}
  ${media.desktop`
    grid-template-columns: 80px 1fr;
    margin: 0 18px;
  `}

  & img {
    justify-self: start;
    margin-left: 10px;
  }

  & p {
    line-height: 1.3;
  }
`

const Header = styled(Row)`
  grid-template-columns: 1fr 12px;
  border-top: none;
  margin: 0 auto;
  padding: 10px 18px;
`

interface FrequencyDropdownInterface {
  frequency: string
  setFrequency: (frequency: string) => void
}

const FrequencyDropdown: React.FC<FrequencyDropdownInterface> = ({
  frequency,
  setFrequency,
  slug
}) => {
  const options =
    slug === 'pumpkin-plus' || slug === 'skin-hero' || slug === 'bone-broth-plus'
      ? [
          { title: 'Large', weight: '75+ lbs', days: '10', width: 50 },
          { title: 'Medium', weight: '51-75 lbs', days: '15', width: 43 },
          { title: 'Small', weight: '26-50 lbs', days: '20', width: 36 },
          { title: 'Mini', weight: '1-25 lbs', days: '30', width: 27 }
        ]
      : [
          { title: 'Large', weight: '75+ lbs', days: '21', width: 50 },
          { title: 'Medium', weight: '51-75 lbs', days: '30', width: 43 },
          { title: 'Small', weight: '26-50 lbs', days: '45', width: 36 },
          { title: 'Mini', weight: '1-25 lbs', days: '90', width: 27 }
        ]

  const [open, setOpen] = useState(false)
  const toggleMenu = () => {
    setOpen((prevState) => !prevState)
  }

  const defaultOption = options.filter((o) => o.days === frequency)

  if (
    slug === 'poop-bags' ||
    slug === 'plaque-patrol-dental-chews-small' ||
    slug === 'plaque-patrol-dental-chews-large'
  ) {
    return null
  }

  return (
    <Container id="area" onClick={toggleMenu} open={open}>
      <Header>
        <P>Choose your dog size</P>
        <P>
          <DownOutlined />
        </P>
      </Header>
      <Select
        open={open}
        bordered={false}
        showSearch={false}
        showArrow={false}
        getPopupContainer={() => document.getElementById('area')}
        value={frequency}
        onChange={(o) => setFrequency(o)}
        dropdownMatchSelectWidth={false}
        defaultActiveFirstOption
        listHeight={325}
        placeholder={
          <Row>
            <img src={DogIcon} alt="Dog Icon" style={{ width: defaultOption.width }} />
            <div>
              <SmallP>{`${defaultOption.title} Dog (${defaultOption.weight})`}</SmallP>
              <SmallP>{`Ships every ${defaultOption.days} days`}</SmallP>
            </div>
          </Row>
        }
        dropdownStyle={{
          width: '100%',
          padding: 0,
          backgroundColor: colors.lightGrey,
          boxShadow: 'none',
          borderLeft: `1px solid ${colors.navy}`,
          borderRight: `1px solid ${colors.navy}`,
          borderBottom: `1px solid ${colors.navy}`,
          borderBottomLeftRadius: 4,
          borderBottomRightRadius: 4
        }}
      >
        {options.reverse().map((row) => {
          const detailedTitle = `${row.title} Dog (${row.weight}) - Ships every ${row.days} days`
          return (
            <React.Fragment key={row.days}>
              <Select.Option
                label={detailedTitle}
                title={detailedTitle}
                value={String(row.days)}
                style={{ padding: 0 }}
              >
                <Row label={detailedTitle}>
                  <img src={DogIcon} alt="Dog Icon" style={{ width: row.width || 'auto' }} />
                  <div>
                    <SmallP>{`${row.title} Dog (${row.weight})`}</SmallP>
                    <SmallP>{`Ships every ${row.days} days`}</SmallP>
                  </div>
                </Row>
              </Select.Option>
            </React.Fragment>
          )
        })}
      </Select>
    </Container>
  )
}

export default FrequencyDropdown
