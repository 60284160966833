import React from 'react'
import styled from 'styled-components'

import SubscriptionGuideGroup from './SubscriptionGuideGroup'
import RadioRow from './RadioRow'
import { media } from 'src/styles/util'
import determineSavings from './determineSavings'

import { IProductSelectorProps } from 'src/types/pdp'

const Container = styled.div`
  position: relative;

  width: 100%;
  height: fit-content;

  display: grid;

  ${media.mobile`
    justify-content: stretch;
    justify-items: center;
    padding: 6px;
  `}
  ${media.tablet`
    grid-row-gap: 10px;
    border: none;
    justify-items: start;
    padding: 0;
  `}
`

const SelectionGroup = styled.div`
  width: 100%;

  display: grid;
  row-gap: 10px;

  ${media.mobile`
    grid-template-columns: 1fr;
  `}
  ${media.desktop`
    grid-template-columns: 1fr 90px;
    column-gap: 10px;
  `}
`

const getPackQuantity = (packType: string) => {
  const [, packQuantity = 1] = packType.split('_')
  return Number(packQuantity)
}

const ProductSelector: React.FC<IProductSelectorProps> = (props) => {
  const {
    productOptions,
    activeVariant,
    frequency,
    setFrequency,
    isBundle,
    isSubscription,
    brandColors,
    clickQuantityButton,
    handleRadioClick,
    slug
  } = props

  if (!activeVariant || !productOptions) return null

  const packQuantity = getPackQuantity(activeVariant.packType)
  const savingsData = { productOptions, activeVariant, isSubscription, packQuantity }
  const [savingsDescription, crossedOutPrice] = determineSavings(savingsData)

  const activePrice = activeVariant?.pricing?.oneTime?.price
  const subPrice =
    slug === 'poop-bags'
      ? productOptions?.multipack_2?.pricing?.subscription?.price
      : productOptions?.single?.pricing?.subscription?.price
  const subPercentageDiscount =
    slug === 'poop-bags'
      ? productOptions?.multipack_2?.pricing?.subscription?.percentageDiscount
      : productOptions?.single?.pricing?.subscription?.percentageDiscount

  const otpOptions = Object.values(productOptions)

  const clickOtp = () => handleRadioClick(false)
  const clickSubscription = () => handleRadioClick(true)

  if (!activePrice || !subPrice) return null

  return (
    <Container>
      {/* ONE TIME */}
      <SelectionGroup>
        <RadioRow
          isBundle={isBundle}
          active={!isSubscription}
          onRadioClick={clickOtp}
          label="One-Time Purchase"
          crossedOutPrice={crossedOutPrice}
          price={activePrice}
          packQuantity={packQuantity}
          otpOptions={otpOptions}
          clickQuantityButton={clickQuantityButton}
          savingsDescription={savingsDescription}
          activeVariant={activeVariant}
        />
      </SelectionGroup>

      {/* SUBSCRIPTION */}
      {subPrice && (
        <SelectionGroup>
          <RadioRow
            isBundle={isBundle}
            isSubscription={isSubscription}
            active={isSubscription}
            onRadioClick={clickSubscription}
            label={`Subscribe & Save ${subPercentageDiscount}`}
            crossedOutPrice={crossedOutPrice}
            price={subPrice}
            packQuantity={1}
            frequency={frequency}
            setFrequency={setFrequency}
            savingsDescription={savingsDescription}
            activeVariant={activeVariant}
            slug={slug}
          />

          {slug !== 'poop-bags' ||
            slug !== 'plaque-patrol-dental-chews-small' ||
            slug !== 'plaque-patrol-dental-chews-large' ||
            slug !== 'paw-hero' ||
            (slug !== 'skin-hero' && (
              <SubscriptionGuideGroup brandColors={brandColors} isSubscription={isSubscription} />
            ))}
        </SelectionGroup>
      )}
    </Container>
  )
}

export default ProductSelector
